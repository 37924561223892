<template>
  <div class="allocate-records">
    <div class="title">发券记录</div>
    <ym-table-list :data="data"
                   :columns="columns"
                   operatorWidth="100px"
                   :pageLayout="pageLayout"
                   :total="total"
                   :pageNumber="page"
                   @handlePageJump="handlePageJump">

      <template #topControl>
        <div class="top-control clearfix">
          <!-- 选择的状态条件标签 -->
          <div class="dropdownlist-tag"
               v-if="showSelectedTag">
            <!-- <template v-for="status in selectedStatus">
              <el-tag closable
                      @close="clearSelectedStatus"
                      disable-transitions
                      :key="status">{{status}}</el-tag>
            </template> -->
            <el-tag closable
                    @close="clearSelectedStatus"
                    disable-transitions>{{selectedStatus === '0' ? '发放成功' : '发放失败'}}</el-tag>
          </div>

          <div class="top-control-refresh">
            <el-button icon="el-icon-refresh"
                       @click="handleRefresh">
            </el-button>
          </div>
          <div class="top-control-search">
            <el-input v-model="search"
                      placeholder="搜索发放对象手机号"
                      prefix-icon="el-icon-search"
                      clearable
                      @clear="handleSearch"
                      @keyup.enter.native="handleSearch">
            </el-input>
          </div>
          <div class="top-control-date">
            <el-date-picker v-model="searchDatePeriod"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            @change="handleSearch">
            </el-date-picker>
          </div>
        </div>
        <!-- 状态选择菜单 -->
        <div class='dropdownlist'>
          <el-dropdown trigger="click"
                       @command="selectStatus">
            <el-button>发放结果</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="all">全部</el-dropdown-item>
              <el-dropdown-item command="success">发放成功</el-dropdown-item>
              <el-dropdown-item command="failure">发放失败</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
      <template #message="{row}">
        <el-alert v-if="row.code === 0"
                  :title="row.message"
                  type="success"
                  :closable="false"
                  show-icon=""></el-alert>

        <el-alert v-else
                  :title="row.message"
                  type="error"
                  :closable="false"
                  show-icon=""></el-alert>
      </template>

      <template #time="{row}">
        {{row.time | timeFilter('YYYY.MM.DD HH:mm:ss')}}
      </template>

      <!-- <template #operatorCol="{row}">
        <el-button class="operator-btn ym-icon-liulan"
                   @click.stop="showUnitDetail(row)">详情</el-button>
      </template> -->

    </ym-table-list>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    YmTableList
  },
  data() {
    return {
      data: [],
      columns: [{
        label: '票券名称',
        prop: 'ticketName',
        align: 'center'
      }, {
        label: '发放者',
        prop: 'sendUserName',
        align: 'center'
      }, {
        label: '兑换码',
        prop: 'exchangeCode',
        align: 'center'
      }, {
        label: '发放对象',
        prop: 'phone',
        align: 'center'
      },
      {
        label: '发放数量',
        prop: 'quantity',
        align: 'center'
      },
      {
        label: '发放结果',
        prop: 'message',
        align: 'center',
        enableColSlot: true
      },
      {
        label: '发放时间',
        prop: 'time',
        align: 'center',
        enableColSlot: true
      }],
      pageLayout: 'prev, pager, next, jumper',
      total: 10, // 数据总数
      // selectedStatus: null, // 已选择的状态
      selectedStatus: '',
      showSelectedTag: false, // 是否存在已选择的状态
      search: '', // 搜索内容
      searchDatePeriod: null,
      size: 10,
      page: 1
    }
  },
  watch: {
    selectedStatus() {
      this.showSelectedTag = this.hasSelected()
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 对搜索框及状态选择标签进行搜索
    handleSearch() {
      this.page = 1
      this.getList()
    },
    // 清空搜索条件(包括状态选择和搜索文本)
    handleRefresh() {
      // this.selectedStatus = null
      this.selectedStatus = ''
      this.search = ''
      this.searchDatePeriod = null
      this.handleSearch()
    },
    // 是否存在筛选条件
    hasSelected() {
      if (this.selectedStatus) {
        return true
      } else {
        return false
      }
    },
    // 筛选状态条件
    selectStatus(command) {
      // switch (command) {
      //   case 'all':
      //     this.selectedStatus = null
      //     break
      //   case 'success':
      //     this.selectedStatus = []
      //     this.selectedStatus.push('发放成功')
      //     break
      //   case 'failure':
      //     this.selectedStatus = []
      //     this.selectedStatus.push('发放失败')
      //     break
      //   default:
      //     this.selectedStatus = null
      //     break
      // }
      switch (command) {
        case 'all':
          this.selectedStatus = ''
          break
        case 'success':
          this.selectedStatus = '0'
          break
        case 'failure':
          this.selectedStatus = '500'
          break
        default:
          this.selectedStatus = ''
          break
      }
      this.handleSearch()
    },
    // 清楚筛选条件
    clearSelectedStatus() {
      this.selectedStatus = null
      this.handleSearch()
    },
    // 处理页码跳转
    handlePageJump(page, pageSize) {
      this.page = page
      this.getList()
    },
    // 获取当天日期，并设置时间为00:00:00
    setDateStart(d) {
      d.setHours(0)
      d.setMinutes(0)
      d.setSeconds(0)
      return d
    },
    setDateEnd(d) {
      d.setHours(23)
      d.setMinutes(59)
      d.setSeconds(59)
      return d
    },
    getList() {
      let begin = this.searchDatePeriod ? new Date(this.setDateStart(this.searchDatePeriod[0])).getTime() : ''
      let end = this.searchDatePeriod ? new Date(this.setDateEnd(this.searchDatePeriod[1])).getTime() : ''
      this.$http.ticket.getTicketRecordList({
        status: this.selectedStatus,
        beginTime: begin,
        endTime: end,
        phone: this.search,
        page: this.page,
        size: this.size
      }).then(res => {
        if (res.data.code === 0) {
          this.data = res.data.data.records
          this.total = res.data.data.total
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.allocate-records {
  .title {
    font-size: 20px;
    font-weight: 600;
    color: $--color-text-primary;
    margin-bottom: 30px;
  }
  .top-control {
    margin-top: 16px;
    margin-bottom: 16px;
    .dropdownlist-tag {
      float: left;
      margin-left: 32px;
      .el-tag {
        position: relative;
        width: 110px;
        height: 40px;
        font-size: 14px;
        line-height: 41px;
        background-color: $--color-primary-light-9;
        border: none;
        border-radius: 6px;
        color: $--color-text-primary;
        padding-left: 20px;
        padding-right: 11px;
        cursor: default;
        .el-icon-close {
          position: absolute;
          right: 11px;
          top: 50%;
          margin-top: -8px;
          color: $--color-text-primary;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
    &-search {
      float: right;
      width: 240px;
      margin-right: 24px;
      .el-input {
        border-radius: 6px;
      }
    }
    &-refresh {
      float: right;
      margin-right: 24px;
      .el-button {
        padding: 8px 11px;
        .el-icon-refresh::before {
          font-size: 20px;
        }
      }
    }
    &-date {
      float: right;
      margin-right: 24px;
      .el-date-editor {
        .el-range-separator {
          width: 7%;
          padding: 0;
        }
      }
    }
  }
  .dropdownlist {
    padding-top: 19px;
    padding-bottom: 19px;
    border-top: 1px solid #e5e8f3;
    .el-button {
      width: 90px;
      height: 41px;
      margin-left: 32px;
    }
  }
}
</style>
